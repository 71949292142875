import React from "react"
import { makeStyles } from "@material-ui/core"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "1%",
    width: "98%",
    minHeight: "100%",
    border: 0,
    backgroundColor: "#7769927a",
    padding: 15,
    borderRadius: 1,
  },
}))

const Administracion = ({ data }) => {
  const classes = useStyles()

  return (
    <Layout>
      <iframe
        className={classes.iframevideo}
        // src={"https://servidor.virtualeventweb.com:1330/admin"}
        src={data.strapiConfiguracion.administracion_link}
        title="Backend administracion"
        //  allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        allow="autoplay"
      />
    </Layout>
  )
}

export default Administracion

export const query = graphql`
  query {
    strapiConfiguracion {
      administracion_link
    }
  }
`
